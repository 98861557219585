export function useMFIAuth() {
  const { status } = useAuthState()
  const { getSession, signOut } = useAuth()
  const toast = useToast()
  const { t } = useI18n()
  const dialog = useDialog()

  const runIfAuthenticated = (
    callback: () => void,
    accountDialogTitle?: string,
    accountDialogDescription?: string
  ) => {
    getSession({})
      .then((session) => {
        if (Object.keys(session).length === 0) {
          throw new Error('Session is empty')
        }
        callback()
      })
      .catch(() => {
        if (status.value === 'authenticated') {
          toast.add({
            severity: 'error',
            summary: t('error.sessionExpired.title'),
            detail: t('error.sessionExpired.message'),
            group: 'custom-toast',
            life: 5000
          })
          signOut()
        } else if (accountDialogTitle && accountDialogDescription) {
          import('~/components/create-account-dialog.vue').then((module) => {
            const CreateAccountDialog = module.default
            dialog.open(CreateAccountDialog, {
              props: {
                style: {
                  width: '60vw'
                },
                breakpoints: {
                  '1130px': '75vw',
                  '840px': '90vw'
                },
                modal: true,
                dismissableMask: true,
                showHeader: false,
                pt: {
                  root: 'max-w-[904px]',
                  content: '!px-0'
                }
              },
              data: {
                title: accountDialogTitle,
                description: accountDialogDescription
              }
            })
          })
        } else {
          toast.add({
            severity: 'error',
            summary: t('error.featureRequiresLogin.title'),
            detail: t('error.featureRequiresLogin.message'),
            group: 'custom-toast',
            life: 3000
          })
        }
      })
  }

  return { runIfAuthenticated }
}
